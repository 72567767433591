import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography, Toolbar, Fab} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';

import theme, {styles} from '../layout/theme';
import Utils from '../api/api';

import ReactTable from './shares/react_table';
import ReactLazyCardMedia from './shares/react_lazy_card';


export class UserList extends Component {
  constructor(props){
    super(props);
    this.state = {
      usergroup: []
    };
  }
  componentDidMount(){
    var $this = this;
    Utils.getListData('usergroup','all',100,'0,0',null,1,(data) => {
      $this.setState({usergroup:data.results});
    });
  }
  handleAdd(){
    this.context.router.history.push('/users/add');
  }
  
  render(){
    const $this = this;
    const classes = this.props.classes;

    var source = {
      thead: [{ title: 'Avatar', value: 'avatar' }, { title: 'User', value: 'username' }, { title: 'Tên', value: 'title' }, { title: 'Email', value: 'email' }, { title: 'Group', value: 'group' }],
      tbody: function (n) {
        return ({
          _id: n._id,
          username: n.username,
          title: n.title,
          email: n.email,
          avatar: (<div className={classes.thumbnailWrapper}><ReactLazyCardMedia className={classes.thumbnail} image={n.avatar !== '' && n.avatar !== undefined ? Utils.fixImagePath(n.avatar) : Utils.fixImagePath('/data/upload/images/no_photo.png')} title={n.username} /></div>),
          group: $this.state.usergroup.length&&$this.state.usergroup.map(x=>{return x._id==n.groupid?x.name:''})
          //group: ''
        })
      }
    };

    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography variant="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục tài khoản</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar + ' flex-right'}>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='users' apiTable='users' router={this.context.router} dataMap={source} showCheckBox={false} keyword='' isSearch={false} >
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
};
UserList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(UserList);
