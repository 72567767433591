import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
import {TextField,Typography,Button} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import ImageControl from './shares/react_image_control';
import language from '../layout/language';
import classNames from 'classnames';
import Utils from '../api/api';
import theme,{styles} from '../layout/theme';

class UserDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: {
        _id:'',
        username:'',
        password:'',
        title:'',
        email:'',
        groupid:'',
        avatar:'',
      },
      username_error:false,
      title_error:false,
      password_error:false,
      error:false,
      failed:false,
      message:'',
      isUpdate: false,
      usergroup: [],
      isAdmin: false,
      oPassword: ''
    };
  }
  componentDidMount(){
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getListData('usergroup','all',100,'0,0',null,1,function(data){
      var g = localStorage.getItem('userg');
      if(g!==null) g = JSON.parse(g);
      if(Utils.atou(g.group)===data.results[0]['_id']) {

        $this.setState({isAdmin:true});
      }
      $this.setState({usergroup:data.results});
    });

    if(params.id!==undefined){
      this.setState({isUpdate:true},function(){
        Utils.getSingleData('users',params.id,function(res){
          //console.log(res);
          if(res.status==='success'&&res.results){
            var data={};
            data.username = res.results.username;
            data.title = res.results.title;
            data.email = res.results.email;
            data.avatar = res.results.avatar;
            data.groupid = res.results.groupid;
            data.password = res.results.password;
            data._id = res.results._id;
            $this.setState({
              user: data,
              oPassword: data.password
            },function(){
              //console.log($this.state);
            });
          }
        });
      });
    }
  }
  handleValidate(e){
    var $this=this,
        obj = this.state,
        user = obj.user;

    user[e.target.id] = e.target.value;

    if(e.target.id==='groupid'){
      user[e.target.id] = document.getElementById('groupid').value;
    }

    //console.log(e.target.id);
    var p = e.target.id+'_error';
    if(e.target.value.trim()===''||e.target.value===null){
      obj[p] = true;
    }else{
      obj[p] = false;
    }
    obj['failed'] = false;
    this.setState(obj,()=>{
      if(!this.state.username_error && !this.state.title_error && !this.state.password_error){
        obj['error'] = false;
      }else{
        obj['error'] = true;
      }
      this.setState(obj,()=>{
        //console.log($this.state);
      });
    });
  }
  handleSave(e){
    var params = this.context.router.route.match.params;
    //console.log(params);
    if(params.id!==undefined&&!this.state.isUpdate){
      this.setState({isUpdate:true})
    }
    var $this = this;
    var obj = {};
    if(this.state.error){
      return;
    }else{
      if(this.state.user.username===''||this.state.user.password===''||this.state.user.title===''){
        //this.state.error = true;
        if(this.state.user.title==='')obj['title_error']=true;
        if(this.state.user.password==='')obj['password_error']=true;
        if(this.state.user.username==='')obj['username_error']=true;
        obj['error'] = true;
        this.setState(obj);
        return;
      }else{
        //var data = {title:this.state.title,username:this.state.username,password:this.state.password,email:this.state.email,avatar:this.state.avatar,groupid:this.state.groupid};
        var data = this.state.user;

        if(this.state.isUpdate){
          //data._id = this.state.user._id;
          data.isUpdatePass = false;
          // console.log(data.password);
          // console.log(this.state.oPassword);
          if(data.password!==this.state.oPassword && this.state.isAdmin) {
            data.isUpdatePass = true;
          }
          Utils._update('users',data,function(res){
            //console.log(res);
            if(res.status==='success'){
              $this.context.router.history.push('/users/');
            }else{
              $this.setState({failed:true,message:res.message});
            }
          });
        }else{
          Utils._add('users',data,function(res){
            //console.log(res);
            if(res.status==='success'){
              $this.context.router.history.push('/users/');
            }else{
              $this.setState({failed:true,message:res.message});
            }
          });
        }
      }
    }
  }
  handleCancel(){
    this.context.router.history.push('/users/');
  }
  handleImageChange(value){
    var $this = this,
        user = $this.state.user;
    user.avatar = value;
    this.setState({user:user},function(){
      //console.log($this.state);
    });
  }
  render(){
    const classes = this.props.classes;
    const user = this.state.user;
    return(
      <div className="page-detail">
        <form className={classes.form} noValidate>
          <Typography type="body2" className={classNames(classes.error, !this.state.error && classes.hide)}>Vui lòng nhập tất cả các field (*)</Typography>
          <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
          <TextField
            id="username"
            label="Username"
            className={classes.textField}
            value={user.username}
            onChange={(event) => this.handleValidate(event)}
            onBlur={(event) => this.handleValidate(event)}
            margin="normal"
            required={true}
            error={this.state.username_error}
          />
          <FormGroup className={classes.formRow}>
            <Typography className={classes.label} type="caption">Nhóm tài khoản</Typography>
            <select id="groupid" className={'select ' + classes.select} value={user.groupid} onChange={(event) => this.handleValidate(event)}>
              {this.state.usergroup.map((item, index) => (
                <option key={index + 1} value={item._id}>{item.name}</option>
              ))}
            </select>
          </FormGroup>
          <TextField
            id="password"
            label="Mật khẩu"
            type="password"
            className={classes.textField}
            value={user.password}
            onChange={(event) => this.handleValidate(event)}
            onBlur={(event) => this.handleValidate(event)}
            margin="normal"
            disabled={!this.state.isAdmin}
            required={true}
            error={this.state.password_error}
          />
          <TextField
            id="title"
            label="Họ tên"
            type="text"
            className={classes.textField}
            value={user.title}
            onChange={(event) => this.handleValidate(event)}
            onBlur={(event) => this.handleValidate(event)}
            margin="normal"
            required={true}
            error={this.state.title_error}
            datapass={this.state.oPassword}
          />
          <TextField
            id="email"
            label="Email"
            type="email"
            className={classes.textField}
            value={user.email}
            onChange={(event) => this.handleValidate(event)}
            onBlur={(event) => this.handleValidate(event)}
            margin="normal"
          />
          <ImageControl value={user.avatar} handleChange={(value) => this.handleImageChange(value)}></ImageControl>
          <FormGroup row className={classes.formRow}>
            <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
            <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event)}>{language.VN.btnSave}</Button>
          </FormGroup>
        </form>
      </div>
    )
  }
}

UserDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
UserDetail.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(UserDetail);
